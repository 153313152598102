import _ from 'underscore';

import masterDataApi from 'adready-api/api/masterData';
import { formatNumberAsFullNumber } from '@/util/numeral';
import mediaPublisherApi from '~/api/media-publisher';
import appConsoleDataLayer from '~/api/app-console-data';
import { dedupe } from './dedupe';
import store from '~/store';

const masterDataLoaders = {
  /**
   * Checks if the given data is available (already loaded and in the store)
   * @param {String} key
   */
  isDataLoaded(key) {
    const data = store.get(key);
    return data && !_.isEmpty(data);
  },

  async loadMasterData(key, api) {
    if (this.isDataLoaded(key)) {
      return []; // nothing to do, bail out
    }
    return dedupe(key, () => {
      const fn = masterDataApi[api];
      if (!fn) {
        return Promise.reject(new Error(`error: missing API method for ${api}`));
      }

      return fn()
        .then((res) => {
          store.set(key, res);

          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, api));
    });
  },

  async loadProductCategories() {
    return this.loadMasterData('common/productCategories', 'productCategories');
  },
  async loadRoleMasters() {
    return this.loadMasterData('common/allRoles', 'roles');
  },
  async loadLiStatus() {
    const liStatusOptions = await masterDataApi.statusOptions('LI');
    return store.set('common/liStatusOptions', liStatusOptions);
  },
  async loadApplications() {
    return this.loadMasterData('common/allApplications', 'applicationOptions');
  },
  async loadDeviceTypes() {
    return this.loadMasterData('common/allDeviceTypes', 'deviceTypesByCategoryId');
  },
  async loadBrandSafetyLevels() {
    return this.loadMasterData('common/allBrandSafetyLevels', 'brandSafetyLevels');
  },
  async loadVerificationPartners() {
    return this.loadMasterData('common/allVerificationPartners', 'verificationPartners');
  },

  async loadWhitelabelDomains() {
    return this.loadMasterData('common/whitelabelDomains', 'whitelabelDomains');
  },
  async loadMediaPublishers() {
    const rawData = await mediaPublisherApi.mediaPublisher();
    const data = rawData ? rawData.data : [];
    const mediaPublisherCPMData = data
      .filter((o) => {
        const ldpDol = o.lowDealPerformance ? o.lowDealPerformance.cpm : 0.0;
        const hdpDol = o.highDealPerformance ? o.highDealPerformance.cpm : 0.0;
        return ldpDol > 0 || hdpDol > 0;
      })
      .map((o) => {
        let lowCPM = o.lowDealPerformance ? o.lowDealPerformance.cpm : 0.0;
        let highCPM = o.highDealPerformance ? o.highDealPerformance.cpm : 0.0;
        const ldpDol = formatNumberAsFullNumber(lowCPM, 'CURRENCY', '00');
        const hdpDol = formatNumberAsFullNumber(highCPM, 'CURRENCY', '00');
        o.range = `(${ldpDol} to ${hdpDol})`;
        if (lowCPM === 0) {
          lowCPM = highCPM - 3;
        }
        if (highCPM === 0) {
          highCPM = lowCPM + 5;
        }
        o.lowCPM = lowCPM;
        o.highCPM = highCPM;
        o.avgCPM = (lowCPM + highCPM) / 2;
        return o;
      });
    return store.set('common/mediaPublisherCPMData', mediaPublisherCPMData);
  },
  async fetchDemoAdvertiserMappings() {
    const key = 'common/demoAdvertiserMappings';
    if (this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key));
    }

    return dedupe(key, () => {
      return appConsoleDataLayer
        .fetchDemoAdvertiserMappings()
        .then((response) => {
          store.set(key, response.data);
          return response.data;
        })
        .catch((e) => this.forkliftErrHandler(e, 'fetchDemoAdvertiserMappings'));
    });
  },
};

export default masterDataLoaders;
