import _ from 'underscore';
import {
  MNI_MOTTO_SOLUTION_ID,
  DISPLAY_STATUS_NAMES_MAP,
  DYNAMIC_PIXEL_CONVERSION_COLUMNS,
  CONVERGED_TV_SOLUTION_ID,
} from '@/constant';
import { isBlank } from 'adready-api/helpers/common';
import { formatDate } from '@/util/apiDateFormat';
import appConsoleHttpAPI from '~/api/app-console-http';
import { buildQueryString } from '~/helpers/global/url-helpers';

const appConsoleDataLayer = {
  async getSolutionsByAdvertiser(advertiserId) {
    try {
      // const data = await appConsoleLocalAPI.getSolutionsByAdvertiser(advertiserId);
      const data = await appConsoleHttpAPI.getSolutionsByAdvertiser(advertiserId);
      return data;
    } catch (err) {
      console.error('error in getSolutionsByAdvertiser ->', err, advertiserId);
      return [];
    }
  },
  async fetchCampaignOptions(
    advertiserId,
    advertiserName,
    accountName,
    solutionId,
    hasXandrAdvertiserId
  ) {
    try {
      let data;
      if (solutionId === CONVERGED_TV_SOLUTION_ID || solutionId === MNI_MOTTO_SOLUTION_ID) {
        const payload = { advertiser: advertiserName, client: accountName };
        /*
        data = await appConsoleLocalAPI.fetchFlipCampaignOptions(
          advertiserId,
          buildQueryString(payload)
        );
        */
        if (hasXandrAdvertiserId) {
          data = await appConsoleHttpAPI.fetchFlipCampaignOptions(
            advertiserId,
            buildQueryString(payload)
          );
        } else {
          data = [];
        }
      } else {
        const payload = { solutionId };
        /*
        data = await appConsoleLocalAPI.fetchCampaignOptions(
          advertiserId,
          buildQueryString(payload)
        );
        */
        data = await appConsoleHttpAPI.fetchCampaignOptions(
          advertiserId,
          buildQueryString(payload)
        );
      }
      return data;
    } catch (err) {
      console.error(
        'error in fetchCampaignOptions ->',
        err,
        advertiserId,
        advertiserName,
        accountName,
        solutionId
      );
      return [];
    }
  },
  async fetchAdGroupOptions(advertiserId, solutionId, campaignsData) {
    const campaignIds =
      campaignsData
        ?.filter((e) => e.checked)
        ?.map((e) => e[solutionId === CONVERGED_TV_SOLUTION_ID ? 'campaignId' : 'key'])
        ?.filter((fres) => fres)
        ?.join(',') || '';
    const payload = {};
    if (solutionId === CONVERGED_TV_SOLUTION_ID) {
      if (campaignIds) {
        payload.planIds = campaignIds;
      }
    } else {
      payload.solutionId = solutionId;
      if (campaignIds) {
        payload.campaignIds = campaignIds;
      }
    }

    try {
      let data;
      if (solutionId === CONVERGED_TV_SOLUTION_ID) {
        /*
        data = await appConsoleLocalAPI.fetchFlipAdGroupOptions(
          advertiserId,
          buildQueryString(payload)
        );
        */
        data = await appConsoleHttpAPI.fetchFlipAdGroupOptions(
          advertiserId,
          buildQueryString(payload)
        );
        const localData = [];
        data.data.forEach((adg) => {
          localData.push({
            name: adg.lineItemName,
            adreadyIds: adg.liData,
          });
        });

        data = localData;
      } else if (solutionId === MNI_MOTTO_SOLUTION_ID) {
        // adgroups are not used in mni solution
        return [];
      } else {
        /*
        data = await appConsoleLocalAPI.fetchAdGroupOptions(
          advertiserId,
          buildQueryString(payload)
        );
        */
        data = await appConsoleHttpAPI.fetchAdGroupOptions(advertiserId, buildQueryString(payload));
      }
      return data;
    } catch (err) {
      console.error('error in fetchAdGroupOptions ->', err, advertiserId, payload);
      return [];
    }
  },
  async fetchBrandSummary(advertiserId, solutionId, payload) {
    try {
      const data = await appConsoleHttpAPI.fetchBrandSummary(
        [advertiserId, solutionId],
        buildQueryString(payload)
      );
      // const data = await appConsoleLocalAPI.fetchBrandSummary(payload);
      return data;
    } catch (err) {
      console.error('error in fetchBrandSummary ->', err, advertiserId, solutionId, payload);
      return {};
    }
  },
  async fetchBrandTopPerformerData(advertiserId, solutionId, payload) {
    try {
      const data = await appConsoleHttpAPI.fetchBrandTopPerformerData(
        [advertiserId, solutionId],
        buildQueryString({ ...payload })
      );
      // const data = await appConsoleLocalAPI.fetchBrandTopPerformerData(payload);
      return data;
    } catch (err) {
      console.error(
        'error in fetchBrandTopPerformerData ->',
        err,
        advertiserId,
        solutionId,
        payload
      );
      return [];
    }
  },
  async fetchBrandDistributionData(
    advertiserId,
    solutionId,
    dimension,
    measurement,
    pixelIds,
    payload
  ) {
    try {
      const copyOfPayload = { ...payload, dimension, measurement, pixelIds };
      // const data = await appConsoleLocalAPI.fetchBrandDistributionData(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchBrandDistributionData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchBrandDistributionData ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        measurement,
        pixelIds,
        payload
      );
      return [];
    }
  },
  async fetchBrandMetricDistributionData(
    advertiserId,
    solutionId,
    dimension,
    metric,
    pixelIds,
    payload
  ) {
    try {
      const copyOfPayload = { ...payload, dimension, metric, pixelIds };
      // const data = await appConsoleLocalAPI.fetchBrandMetricDistributionData(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchBrandMetricDistributionData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchBrandMetricDistributionData ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        metric,
        pixelIds,
        payload
      );
      return [];
    }
  },
  async fetchBrandDimensionPerformance(requestId, advertiserId, solutionId, dimension, payload) {
    try {
      const copyOfPayload = { ...payload, dimension };
      // const data = await appConsoleLocalAPI.fetchBrandDimensionPerformance(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchBrandDimensionPerformance(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );

      if (data && data.length) {
        data.forEach((r) => {
          r.status = DISPLAY_STATUS_NAMES_MAP[r.statusId] || '';
        });
      }

      return { requestId, data };
    } catch (err) {
      console.error(
        'error in fetchBrandDimensionPerformance ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        payload
      );
      return { requestId, data: [] };
    }
  },
  async fetchBrandMeasurementDailyViewData(advertiserId, solutionId, measurement, payload) {
    try {
      const copyOfPayload = { ...payload };
      if (!_.isEmpty(measurement)) {
        copyOfPayload.measurement = measurement;
      }
      // const data = await appConsoleLocalAPI.fetchBrandDailyViewData(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchBrandMeasurementDailyViewData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchBrandMeasurementDailyViewData ->',
        err,
        advertiserId,
        solutionId,
        measurement,
        payload
      );
      return [];
    }
  },
  async fetchBrandMetricDailyViewData(advertiserId, solutionId, metric, payload) {
    try {
      const copyOfPayload = { ...payload };
      if (!_.isEmpty(metric)) {
        copyOfPayload.metric = metric;
      }
      // const data = await appConsoleLocalAPI.fetchBrandDailyViewData(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchBrandMetricDailyViewData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchBrandMetricDailyViewData ->',
        err,
        advertiserId,
        solutionId,
        metric,
        payload
      );
      return [];
    }
  },
  async fetchBrandDimensionReport(requestId, advertiserId, solutionId, dimension, payload) {
    try {
      const copyOfPayload = { ...payload, dimension };
      const data = await appConsoleHttpAPI.fetchBrandDimensionReport(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return { requestId, data };
    } catch (err) {
      console.error(
        'error in fetchBrandDimensionReport ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        payload
      );
      return { requestId, data: [] };
    }
  },
  async fetchMediaTypeOptions(advertiserId, solutionId, payload) {
    try {
      const copyOfPayload = { ...payload, endDate: formatDate(new Date()) };
      let data;
      if (solutionId === CONVERGED_TV_SOLUTION_ID || solutionId === MNI_MOTTO_SOLUTION_ID) {
        copyOfPayload.ioIds = copyOfPayload?.campaignIds || '';
        // data = await appConsoleLocalAPI.fetchFlipMediaTypeOptions(copyOfPayload);
        data = await appConsoleHttpAPI.fetchFlipMediaTypeOptions(
          advertiserId,
          buildQueryString(copyOfPayload)
        );
      } else {
        // data = await appConsoleLocalAPI.fetchMediaTypeOptions(copyOfPayload);
        data = await appConsoleHttpAPI.fetchMediaTypeOptions(
          advertiserId,
          buildQueryString({ solutionId, ...copyOfPayload })
        );
      }
      return data;
    } catch (err) {
      console.error('error in fetchMediaTypeOptions ->', err, advertiserId, solutionId, payload);
      return [];
    }
  },
  async fetchAudienceOptions(advertiserId, solutionId, payload) {
    try {
      const copyOfPayload = { ...payload };
      let data;
      // this below if condition is for skipping audiance API for converge TV
      if (solutionId === CONVERGED_TV_SOLUTION_ID) {
        data = [];
      } else if (solutionId === MNI_MOTTO_SOLUTION_ID) {
        copyOfPayload.ioIds = copyOfPayload?.campaignIds || '';
        // data = await appConsoleLocalAPI.fetchFlipAudienceOptions(copyOfPayload);
        data = await appConsoleHttpAPI.fetchFlipAudienceOptions(
          advertiserId,
          buildQueryString(copyOfPayload)
        );
      } else {
        // data = await appConsoleLocalAPI.fetchAudienceOptions(copyOfPayload);
        const result = await appConsoleHttpAPI.fetchAudienceOptions(
          advertiserId,
          buildQueryString({ solutionId, ...copyOfPayload })
        );
        const filterOptions = [];
        result?.forEach((d) => filterOptions.push({ key: d, value: d }));
        data = filterOptions;
      }

      return data;
    } catch (err) {
      console.error('error in fetchAudienceOptions ->', err, advertiserId, solutionId, payload);
      return [];
    }
  },
  async fetchCreativeOptions(advertiserId, solutionId, payload) {
    try {
      const copyOfPayload = { ...payload };
      let data;
      if (solutionId === CONVERGED_TV_SOLUTION_ID || solutionId === MNI_MOTTO_SOLUTION_ID) {
        copyOfPayload.ioIds = copyOfPayload?.campaignIds || '';
        // data = await appConsoleLocalAPI.fetchFlipCreativeOptions(copyOfPayload);
        data = await appConsoleHttpAPI.fetchFlipCreativeOptions(
          advertiserId,
          buildQueryString(copyOfPayload)
        );
      } else {
        // data = await appConsoleLocalAPI.fetchCreativeOptions(copyOfPayload);
        data = await appConsoleHttpAPI.fetchCreativeOptions(
          advertiserId,
          buildQueryString({ solutionId, ...copyOfPayload })
        );
      }
      return data;
    } catch (err) {
      console.error('error in fetchCreativeOptions ->', err, advertiserId, solutionId, payload);
      return [];
    }
  },
  async fetchKeywordOptions(advertiserId, solutionId, payload) {
    try {
      if (solutionId === MNI_MOTTO_SOLUTION_ID) {
        // keywords are not used in mni solution
        return [];
      }
      const copyOfPayload = { ...payload };
      // const data = await appConsoleLocalAPI.fetchKeywordOptions(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchKeywordOptions(
        advertiserId,
        buildQueryString({ solutionId, ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error('error in fetchKeywordOptions ->', err, advertiserId, solutionId, payload);
      return [];
    }
  },
  async fetchConversionPixelOptions(advertiserId, solutionId, payload) {
    try {
      const copyOfPayload = { ...payload };
      // for fetchting pixel data, we need to pass endDate as yesterday alway irrecpective of the payload value
      // this changes is made to accomodate the changes related to extended ad group data where campagin end date is less than
      // extended ad group end date and we need to show the data for the extended ad group end date
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      copyOfPayload.endDate = formatDate(yesterday);
      // const data = await appConsoleLocalAPI.fetchConversionPixelOptions(copyOfPayload);
      if (solutionId === MNI_MOTTO_SOLUTION_ID) {
        // conversion pixels are not used in mni solution
        return [];
      }
      const data = await appConsoleHttpAPI.fetchConversionPixelOptions(
        advertiserId,
        buildQueryString({ solutionId, ...copyOfPayload })
      );
      if (data && data.length) {
        for (let i = 0; i < data.length; i++) {
          const p = data[i];
          p.dynamicColumns = [];

          const columns = JSON.parse(JSON.stringify(DYNAMIC_PIXEL_CONVERSION_COLUMNS));
          columns[0].key = `pixel_${p.key}_conversions`;
          columns[0].value = p.value;
          columns[1].key = `pixel_${p.key}_cpa`;
          columns[1].infinityKey = `pixel_${p.key}_cpaInfinity`;
          columns[1].value = `${p.value} CPA`;
          columns[2].key = `pixel_${p.key}_revenue`;
          columns[2].value = `${p.value} Revenue`;
          columns[3].key = `pixel_${p.key}_roas`;
          columns[3].infinityKey = `pixel_${p.key}_roasInfinity`;
          columns[3].value = `${p.value} ROAS`;

          p.dynamicColumns.push(...columns);
        }
      }
      return data;
    } catch (err) {
      console.error(
        'error in fetchConversionPixelOptions ->',
        err,
        advertiserId,
        solutionId,
        payload
      );
      return [];
    }
  },
  async fetchSearchSummary(advertiserId, solutionId, payload) {
    try {
      const data = await appConsoleHttpAPI.fetchSearchSummary(
        [advertiserId, solutionId],
        buildQueryString(payload)
      );
      // const data = await appConsoleLocalAPI.fetchSearchSummary(payload);
      return data;
    } catch (err) {
      console.error('error in fetchSearchSummary ->', err, advertiserId, solutionId, payload);
      return {};
    }
  },
  async fetchSearchTopPerformerData(advertiserId, solutionId, payload) {
    try {
      const data = await appConsoleHttpAPI.fetchSearchTopPerformerData(
        [advertiserId, solutionId],
        buildQueryString({ ...payload })
      );
      // const data = await appConsoleLocalAPI.fetchSearchTopPerformerData(payload);
      return data;
    } catch (err) {
      console.error(
        'error in fetchSearchTopPerformerData ->',
        err,
        advertiserId,
        solutionId,
        payload
      );
      return [];
    }
  },
  async fetchSearchDistributionData(
    advertiserId,
    solutionId,
    dimension,
    measurement,
    pixelIds,
    payload
  ) {
    try {
      const copyOfPayload = { ...payload, dimension, measurement, pixelIds };
      // const data = await appConsoleLocalAPI.fetchSearchDistributionData(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchSearchDistributionData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchSearchDistributionData ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        measurement,
        pixelIds,
        payload
      );
      return [];
    }
  },
  async fetchSearchMetricDistributionData(
    advertiserId,
    solutionId,
    dimension,
    metric,
    pixelIds,
    payload
  ) {
    try {
      const copyOfPayload = { ...payload, dimension, metric, pixelIds };
      // const data = await appConsoleLocalAPI.fetchSearchMetricDistributionData(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchSearchMetricDistributionData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );

      if (data && data.length && dimension === 'dma') {
        data.forEach((r) => {
          if (r.extra) {
            r.key = r.extra;
          }
        });
      }

      return data;
    } catch (err) {
      console.error(
        'error in fetchSearchMetricDistributionData ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        metric,
        pixelIds,
        payload
      );
      return [];
    }
  },
  async fetchSearchDimensionPerformance(requestId, advertiserId, solutionId, dimension, payload) {
    try {
      const copyOfPayload = { ...payload, dimension };
      // const data = await appConsoleLocalAPI.fetchSearchDimensionPerformance(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchSearchDimensionPerformance(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );

      if (data && data.length) {
        data.forEach((r) => {
          r.status = DISPLAY_STATUS_NAMES_MAP[r.statusId] || '';
        });
      }

      return { requestId, data };
    } catch (err) {
      console.error(
        'error in fetchSearchDimensionPerformance ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        payload
      );
      return { requestId, data: [] };
    }
  },
  async fetchSearchMeasurementDailyViewData(advertiserId, solutionId, measurement, payload) {
    try {
      const copyOfPayload = { ...payload };
      if (!_.isEmpty(measurement)) {
        copyOfPayload.measurement = measurement;
      }
      // const data = await appConsoleLocalAPI.fetchSearchDailyViewData(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchSearchMeasurementDailyViewData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchSearchMeasurementDailyViewData ->',
        err,
        advertiserId,
        solutionId,
        measurement,
        payload
      );
      return [];
    }
  },
  async fetchSearchMetricDailyViewData(advertiserId, solutionId, metric, payload) {
    try {
      const copyOfPayload = { ...payload };
      if (!_.isEmpty(metric)) {
        copyOfPayload.metric = metric;
      }
      // const data = await appConsoleLocalAPI.fetchSearchDailyViewData(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchSearchMetricDailyViewData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchSearchMetricDailyViewData ->',
        err,
        advertiserId,
        solutionId,
        metric,
        payload
      );
      return [];
    }
  },
  async fetchSearchDimensionReport(requestId, advertiserId, solutionId, dimension, payload) {
    try {
      const copyOfPayload = { ...payload, dimension };
      const data = await appConsoleHttpAPI.fetchSearchDimensionReport(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return { requestId, data };
    } catch (err) {
      console.error(
        'error in fetchSearchDimensionReport ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        payload
      );
      return { requestId, data: [] };
    }
  },
  async activateSolution({ userId, advertiserName, accountName, solutionName }) {
    try {
      const data = await appConsoleHttpAPI.activateSolution(
        userId,
        buildQueryString({ advertiserName, accountName, solutionName })
      );

      return data;
    } catch (err) {
      console.error(
        'error in activateSolution ->',
        err,
        userId,
        advertiserName,
        accountName,
        solutionName
      );
      return { status: null };
    }
  },
  async getConversionPixelOptions(advertiserId, activeSolutionId, payload) {
    const payloadCopy = {
      advertiser: payload.advertiser,
      client: payload.client,
      campaignIds: payload.campaignIds,
      adGroups: payload.adGroups,
      startDate: payload.startDate,
      endDate: payload.endDate,
    };
    const data = await appConsoleDataLayer.fetchConversionPixelOptions(
      advertiserId,
      activeSolutionId,
      payloadCopy
    );
    return data;
  },
  async fetchSocialSummary(advertiserId, solutionId, payload) {
    let copyOfPayload = { ...payload, pixelIds: payload.pixelIds };
    if (isBlank(payload.pixelIds)) {
      const pixelOptions = await this.getConversionPixelOptions(advertiserId, solutionId, payload);
      const converstionPixel = pixelOptions.find((p) => p.value === 'Website Conversions');
      copyOfPayload = { ...copyOfPayload, pixelIds: converstionPixel?.key };
    }

    try {
      const data = await appConsoleHttpAPI.fetchSocialSummary(
        [advertiserId, solutionId],
        buildQueryString(copyOfPayload)
      );
      // const data = await appConsoleLocalAPI.fetchSocialSummary(payload);
      return data;
    } catch (err) {
      console.error('error in fetchSocialSummary ->', err, advertiserId, solutionId, payload);
      return {};
    }
  },
  async fetchSocialTopPerformerData(advertiserId, solutionId, payload) {
    try {
      const data = await appConsoleHttpAPI.fetchSocialTopPerformerData(
        [advertiserId, solutionId],
        buildQueryString({ ...payload })
      );
      // const data = await appConsoleLocalAPI.fetchSocialTopPerformerData(payload);
      return data;
    } catch (err) {
      console.error(
        'error in fetchSocialTopPerformerData ->',
        err,
        advertiserId,
        solutionId,
        payload
      );
      return [];
    }
  },
  async fetchSocialDistributionData(
    advertiserId,
    solutionId,
    dimension,
    measurement,
    pixelIds,
    payload
  ) {
    try {
      const copyOfPayload = { ...payload, dimension, measurement, pixelIds };
      let copyOfPayloadWithPixel = { ...copyOfPayload };
      // const data = await appConsoleLocalAPI.fetchSocialDistributionData(copyOfPayload);
      if (isBlank(pixelIds)) {
        const pixelOptions = await this.getConversionPixelOptions(
          advertiserId,
          solutionId,
          payload
        );
        const converstionPixel = pixelOptions.find((p) => p.value === 'Website Conversions');
        copyOfPayloadWithPixel = { ...copyOfPayload, pixelIds: converstionPixel?.key };
      }
      const data = await appConsoleHttpAPI.fetchSocialDistributionData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayloadWithPixel })
      );

      return data;
    } catch (err) {
      console.error(
        'error in fetchSocialDistributionData ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        measurement,
        pixelIds,
        payload
      );
      return [];
    }
  },
  async fetchSocialMetricDistributionData(
    advertiserId,
    solutionId,
    dimension,
    metric,
    pixelIds,
    payload
  ) {
    try {
      const copyOfPayload = { ...payload, dimension, metric, pixelIds };
      // const data = await appConsoleLocalAPI.fetchSocialMetricDistributionData(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchSocialMetricDistributionData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );

      if (data && data.length && dimension === 'dma') {
        data.forEach((r) => {
          if (r.extra) {
            r.key = r.extra;
          }
        });
      }

      return data;
    } catch (err) {
      console.error(
        'error in fetchSocialMetricDistributionData ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        metric,
        pixelIds,
        payload
      );
      return [];
    }
  },
  async fetchSocialDimensionPerformance(requestId, advertiserId, solutionId, dimension, payload) {
    try {
      const copyOfPayload = { ...payload, dimension };
      // const data = await appConsoleLocalAPI.fetchSocialDimensionPerformance(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchSocialDimensionPerformance(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );

      if (data && data.length) {
        data.forEach((r) => {
          r.status = DISPLAY_STATUS_NAMES_MAP[r.statusId] || '';
        });
      }

      return { requestId, data };
    } catch (err) {
      console.error(
        'error in fetchSocialDimensionPerformance ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        payload
      );
      return { requestId, data: [] };
    }
  },
  async fetchSocialMeasurementDailyViewData(advertiserId, solutionId, measurement, payload) {
    try {
      const copyOfPayload = { ...payload };
      if (!_.isEmpty(measurement)) {
        copyOfPayload.measurement = measurement;
      }
      // const data = await appConsoleLocalAPI.fetchSocialDailyViewData(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchSocialMeasurementDailyViewData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );

      return data;
    } catch (err) {
      console.error(
        'error in fetchSocialMeasurementDailyViewData ->',
        err,
        advertiserId,
        solutionId,
        measurement,
        payload
      );
      return [];
    }
  },
  async fetchSocialMetricDailyViewData(advertiserId, solutionId, metric, payload) {
    try {
      const copyOfPayload = { ...payload };
      if (!_.isEmpty(metric)) {
        copyOfPayload.metric = metric;
      }
      // const data = await appConsoleLocalAPI.fetchSocialDailyViewData(copyOfPayload);
      const data = await appConsoleHttpAPI.fetchSocialMetricDailyViewData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchSocialMetricDailyViewData ->',
        err,
        advertiserId,
        solutionId,
        metric,
        payload
      );
      return [];
    }
  },
  async fetchSocialDimensionReport(requestId, advertiserId, solutionId, dimension, payload) {
    try {
      const copyOfPayload = { ...payload, dimension };
      // const data = await appConsoleLocalAPI.fetchSocialDimensionReport(copyOfPayload);
      let copyOfPayloadWithPixelIds = { ...copyOfPayload };
      if (isBlank(payload.pixelIds)) {
        const pixelOptions = await this.getConversionPixelOptions(
          advertiserId,
          solutionId,
          copyOfPayload
        );
        const converstionPixel = pixelOptions.find((p) => p.value === 'Website Conversions');
        copyOfPayloadWithPixelIds = { ...copyOfPayload, pixelIds: converstionPixel?.key };
      } else {
        copyOfPayloadWithPixelIds = { ...copyOfPayload, pixelIds: payload.pixelIds };
      }

      const data = await appConsoleHttpAPI.fetchSocialDimensionReport(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayloadWithPixelIds })
      );
      return { requestId, data };
    } catch (err) {
      console.error(
        'error in fetchSocialDimensionReport ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        payload
      );
      return { requestId, data: [] };
    }
  },
  async fetchDoohSummary(advertiserId, solutionId, payload) {
    try {
      const data = await appConsoleHttpAPI.fetchDoohSummary(
        [advertiserId, solutionId],
        buildQueryString(payload)
      );
      return data;
    } catch (err) {
      console.error('error in fetchDoohSummary ->', err, advertiserId, solutionId, payload);
      return {};
    }
  },
  async fetchDoohTopPerformerData(advertiserId, solutionId, payload) {
    try {
      const data = await appConsoleHttpAPI.fetchDoohTopPerformerData(
        [advertiserId, solutionId],
        buildQueryString({ ...payload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchDoohTopPerformerData ->',
        err,
        advertiserId,
        solutionId,
        payload
      );
      return [];
    }
  },
  async fetchDoohDistributionData(
    advertiserId,
    solutionId,
    dimension,
    measurement,
    pixelIds,
    payload
  ) {
    try {
      const copyOfPayload = { ...payload, dimension, measurement, pixelIds };
      const data = await appConsoleHttpAPI.fetchDoohDistributionData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchDoohDistributionData ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        measurement,
        pixelIds,
        payload
      );
      return [];
    }
  },
  async fetchDoohMetricDistributionData(
    advertiserId,
    solutionId,
    dimension,
    metric,
    pixelIds,
    payload
  ) {
    try {
      const copyOfPayload = { ...payload, dimension, metric, pixelIds };
      const data = await appConsoleHttpAPI.fetchDoohMetricDistributionData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchDoohMetricDistributionData ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        metric,
        pixelIds,
        payload
      );
      return [];
    }
  },
  async fetchDoohDimensionPerformance(requestId, advertiserId, solutionId, dimension, payload) {
    try {
      const copyOfPayload = { ...payload, dimension };
      const data = await appConsoleHttpAPI.fetchDoohDimensionPerformance(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );

      if (data && data.length) {
        data.forEach((r) => {
          r.status = DISPLAY_STATUS_NAMES_MAP[r.statusId] || '';
        });
      }

      return { requestId, data };
    } catch (err) {
      console.error(
        'error in fetchDoohDimensionPerformance ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        payload
      );
      return { requestId, data: [] };
    }
  },
  async fetchDoohMeasurementDailyViewData(advertiserId, solutionId, measurement, payload) {
    try {
      const copyOfPayload = { ...payload };
      if (!_.isEmpty(measurement)) {
        copyOfPayload.measurement = measurement;
      }
      const data = await appConsoleHttpAPI.fetchDoohMeasurementDailyViewData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchDoohMeasurementDailyViewData ->',
        err,
        advertiserId,
        solutionId,
        measurement,
        payload
      );
      return [];
    }
  },
  async fetchDoohMetricDailyViewData(advertiserId, solutionId, metric, payload) {
    try {
      const copyOfPayload = { ...payload };
      if (!_.isEmpty(metric)) {
        copyOfPayload.metric = metric;
      }
      const data = await appConsoleHttpAPI.fetchDoohMetricDailyViewData(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error(
        'error in fetchDoohMetricDailyViewData ->',
        err,
        advertiserId,
        solutionId,
        metric,
        payload
      );
      return [];
    }
  },
  async fetchDoohDimensionReport(requestId, advertiserId, solutionId, dimension, payload) {
    try {
      const copyOfPayload = { ...payload, dimension };
      const data = await appConsoleHttpAPI.fetchDoohDimensionReport(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );
      return { requestId, data };
    } catch (err) {
      console.error(
        'error in fetchDoohDimensionReport ->',
        err,
        advertiserId,
        solutionId,
        dimension,
        payload
      );
      return { requestId, data: [] };
    }
  },
  async fetchMyReports(advertiserId) {
    try {
      // const data = await appConsoleLocalAPI.fetchMyReports();
      const data = await appConsoleHttpAPI.fetchMyReports(advertiserId);

      return data;
    } catch (err) {
      console.error('error in fetchMyReports ->', err, advertiserId);
      return [];
    }
  },
  // fetchMyAccountReports api implementation
  async fetchMyAccountReports(accountId) {
    try {
      const data = await appConsoleHttpAPI.fetchMyAccountReports(accountId);
      return data;
    } catch (err) {
      console.error('error in fetchMyAccountReports ->', err, accountId);
      return [];
    }
  },
  async downloadMyReport(advertiserId, viewId, type) {
    try {
      const opts = {
        headers: {
          Accept: `application/${type}`,
        },
        respondHeaders: true,
        responseType: 'blob',
      };
      const data = await appConsoleHttpAPI.downloadMyReport([advertiserId, viewId, type], {}, opts);
      return data;
    } catch (err) {
      console.error('error in downloadMyReport ->', err, advertiserId);
      return '';
    }
  },
  async fetchCTVSummary(advertiserId, solutionId, payload) {
    try {
      const copyOfPayload = { ...payload };
      copyOfPayload.campaignIds = copyOfPayload?.campaignIds || '';
      const data = await appConsoleHttpAPI.fetchCTVSummary(
        [advertiserId, solutionId],
        buildQueryString({ ...copyOfPayload })
      );

      return data;
    } catch (err) {
      console.error('error in fetchCTVSummary ->', err, advertiserId, solutionId, payload);
      return [];
    }
  },

  async flipPixels(advertiserId) {
    try {
      const data = await appConsoleHttpAPI.flipPixels(advertiserId);
      return data;
    } catch (err) {
      console.error('error in flipPixels ->', err, advertiserId);
      return [];
    }
  },
  async flipPixelFiresSummary(advertiserId, pixelId, payload) {
    try {
      const copyOfPayload = { ...payload };
      const data = await appConsoleHttpAPI.flipPixelFiresSummary(
        [advertiserId, pixelId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error('error in flipPixelFiresSummary ->', err, advertiserId, pixelId, payload);
      return {};
    }
  },
  async flipPixelCategories(advertiserId, pixelId, payload) {
    try {
      const copyOfPayload = { ...payload };
      const data = await appConsoleHttpAPI.flipPixelCategories(
        [advertiserId, pixelId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error('error in flipPixelCategories ->', err, advertiserId, pixelId, payload);
      return {};
    }
  },
  async flipPixelFires(advertiserId, pixelId, payload, opts) {
    try {
      const copyOfPayload = { ...payload };
      const data = await appConsoleHttpAPI.flipPixelFires(
        [advertiserId, pixelId],
        buildQueryString({ ...copyOfPayload }),
        opts
      );
      return data;
    } catch (err) {
      console.error('error in flipPixelFires ->', err, advertiserId, pixelId, payload);
      return {};
    }
  },
  async flipDailyPixelFires(advertiserId, pixelId, payload) {
    try {
      const copyOfPayload = { ...payload };
      const data = await appConsoleHttpAPI.flipDailyPixelFires(
        [advertiserId, pixelId],
        buildQueryString({ ...copyOfPayload })
      );
      return data;
    } catch (err) {
      console.error('error in flipDailyPixelFires ->', err, advertiserId, pixelId, payload);
      return {};
    }
  },
  async fetchDemoAdvertiserMappings() {
    try {
      const data = await appConsoleHttpAPI.fetchDemoAdvertiserMappings();
      return data;
    } catch (err) {
      console.error('error in fetchDemoAdvertiserMappings ->', err);
      return {};
    }
  },
};

export default appConsoleDataLayer;
